<template>
    <div>
        <div class="loginConiter">
            <div class="loginlabe">
                <div class="shuru" v-if="!isforget">
                    <div class="zhanghu">
                        <div class="tie">账号</div>
                        <el-input placeholder="请输入账户" @keyup.native.enter="register_login" v-model="username" clearable>
                        </el-input>
                    </div>
                    <div class="zhanghu">
                        <div class="tie">密码</div>
                        <el-input placeholder="密码由8-30位数的大小写加数字字符组合" v-model="password" show-password
                            @keyup.native.enter="register_login"></el-input>
                    </div>
                    <div>
                        <ul class="pwd-strong">
                            <li :class="{ weak: pwdLevel === 0 }">弱</li>
                            <li :class="{ better: pwdLevel === 1 }">中</li>
                            <li :class="{ strong: pwdLevel === 2 }">强</li>
                        </ul>
                    </div>
                    <div class="zhanghu">
                        <div class="tie">确认密码</div>
                        <el-input placeholder="密码由8-30位数的大小写加数字字符组合" v-model="passwordSure" show-password
                            @keyup.native.enter="register_login"></el-input>
                    </div>
                    <!-- <div></div> -->
                    <!-- <div class="readtext">
                        <el-radio v-model="agree">阅读并同意 <span>《用户协议》</span>和<span>《隐私保护政策》</span></el-radio>
                    </div> -->
                    <div class="zhanghu">
                        <div class="tie">验证码</div>
                        <el-input placeholder="请输入验证码" v-model="imageCode" @keyup.native.enter="register_login">
                            <template slot="append">
                                <div class="login-code" @click="refreshCode">
                                    <canvas id="canvas" height="40" width="116"></canvas>
                                </div>
                            </template>
                        </el-input>
                    </div>
                    <div class="commit">
                        <div @click="register_login" class="logincomit">注册</div>
                    </div>
                    <div class="logcont2">
                        <div><el-checkbox v-model="isRemember">记住密码</el-checkbox></div>
                        <!-- <div class="forgetPassword" @click="operateFunc()">忘记密码</div> -->
                    </div>
                    <div class="bottom">已有帐号? <span class="clicktext" @click="returnLogin()">点击登录</span></div>
                </div>
                <div v-if="isforget">
                    <forgetPassword @change-value="handleChangeValue"></forgetPassword>
                </div>
            </div>
        </div>
    </div>
</template>  
<script>
import forgetPassword from "./forgetPassword.vue"
import { register_putong } from "@api/user";
import { checkPwdStrong } from "@/utils/index";
export default {
    name: 'enteryRegister',
    components: { forgetPassword },
    inject: ["createCanvas","refreshCode",], //注册刷新验证码
    data() {
        return {
            username: '',
            password: '',
            imageCode:'',
            verifyReCode:'',
            passwordSure: '',
            agree: false,
            isRemember: false,
            isTrue: false,
            isforget: false,
            source: 4 //企业标准编制
        }
    },
    props: {
        isReset: {
            type: Boolean,
            default: false
        },
        isRegister: {
            type: Boolean,
            default: false
        },
        // refreshCode: {
        //     type: Function,
        //     default:(()=>{ })
        // }
    },
    mounted() {
        this.verifyReCode = this.createCanvas()
        // console.log(this.verifyReCode,'打印')
    },
    methods: {
        register_login() {
            let that = this;
            // 判断密码是否一致
            if (that.passwordSure != that.password) {
                that.$message.warning("两次输入的密码不一致!");
                return false
            } else if(that.imageCode != that.verifyReCode) {
                that.$message.error('验证码错误!');
                return false
            }else if (
                that.username &&
                that.username != "" &&
                that.password &&
                that.password != ""
            ) {
                let params = {
                    username: that.username,
                    password: that.password,
                    loginType: 1,
                    source: that.source
                };
                // debugger
                register_putong(params).then((res) => {
                    console.log(res, '打印注册的用户数据的返回');
                    if (
                        res.code === 200
                    ) {
                        that.$message.success("注册成功");
                    } else {
                        that.$message.warning("普通用户暂不支持登录");
                    }
                    //记住密码
                    if (that.isRemember) {
                        localStorage.setItem('loginInfo', JSON.stringify(params))
                    } else if (!that.isRemember) {
                        localStorage.setItem('loginInfo', JSON.stringify({}))
                    }
                });
            } else {
                that.$message.warning("请填写用户名和密码");
            }
        },
        operateFunc() {
            this.isforget = true
            this.$parent.titleStatus = '忘记密码'
            this.$parent.isReset = false
        },
        //点击登录
        returnLogin() {
            this.$parent.isRegister = false
            this.$parent.isLogin = true
            this.$parent.titleStatus = '用户登录'
            this.$parent.refreshCode(); //刷新验证码
        },
        handleChangeValue() {
            this.isforget = false //当前组件也要销毁
            this.$emit('change-grad', false);
        },
        // //同意或取消
        // handleTrigger($event) {
        //     let _this = this;
        //     let objVal = this.value;
        //     window.setTimeout(() => {
        //         if (!!objVal && objVal == $event.target.value) {
        //             $event.target.checked = false
        //             _this.value = ''
        //         }
        //     }, 0);
        // }
    },
    computed: {
        // 密码强度等级
        pwdLevel() {
            return checkPwdStrong(this.password);
        },
    }
}
</script>
<style scoped>
/deep/ .el-radio__input.is-checked+.el-radio__label {
    color: #606266 !important;
}
/deep/.el-input-group__append {
  /* width: 574px; */
  padding: 0 !important;
}
</style>
<style scoped lang="scss">
.loginlabe {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .lotitle {
        .first {
            font-size: 24px;
            font-weight: bold;
            color: #000000;
            margin: 40px 0;
            text-align: center;
        }

        .secong {
            font-size: 36px;
            font-weight: 800;
            color: #000000;
            // margin-bottom: 70px;
            text-align: center;
        }
    }

    .shuru {
        // padding-top: 90px;
        margin: 0 auto;
        width: 360px;
        height: 360px;
        border-radius: 20px;
        // padding: 70px;

        .zhanghu {
            border-bottom: 1px solid #ffffff;
            margin-bottom: 24px;

            .tie {
                font-size: 16px;
                font-weight: bold;
                color: #000000;
                margin-bottom: 10px;
            }
        }
    }

    .readtext {
        span {
            color: #409EFF;
        }
    }

    .logcont2 {
        margin-top: 27px;
        // width: 400px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .forget {
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            text-align: center;
        }
    }

    .forgetPassword {
        font-size: 14px;
        color: #0099fc;
        cursor: pointer;
    }

    .bottom {
        font-size: 14px;
        color: #333;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 26px;

        .clicktext {
            color: #3082e2;
            cursor: pointer;
        }
    }

}

.pwd-strong {
    display: flex;
    // margin-top: 20px;
    // margin-bottom: 10px;
    list-style-type: none;

    li {
        margin-right: 10px;
        width: 50px;
        text-align: center;
        padding-top: 2px;
        border-top: 4px solid #ccc;
        font-size: 13px;
        color: #ccc;

        &.weak {
            border-top: 4px solid #f56c6c;
        }

        &.better {
            border-top: 4px solid #e6a23c;
        }

        &.strong {
            border-top: 4px solid #67c23a;
        }
    }
}

.login-code {
  height: 38px;
}

.commit {
    margin-top: 27px;
    display: flex;
    justify-content: center;
    align-items: center;

    .logincomit {
        width: 378px;
        height: 40px;
        background: #164C90;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        border-radius: 5px;
    }

    .cont {
        width: 574px;
        margin-top: 24px;
        font-size: 20px;
        color: #979797;
        text-align: center;
        cursor: pointer;
    }
}

.logcont2 {
    margin-top: 27px;
    // width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .forget {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        text-align: center;
    }
}
</style>