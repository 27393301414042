<template>
  <div class="loginConiter">
    <div class="leftbg"></div>
    <div class="loginlabe">
      <div class="lotitle">
        <div class="secong">江苏省工程建设企业技术标准认证公告系统</div>
        <div class="first">{{ titleStatus }}</div>
      </div>
      <div class="shuru" v-if="isLogin">
        <div class="zhanghu">
          <div class="tie">账号</div>
          <el-input placeholder="请输入账户" @keyup.native.enter="user_login" v-model="entname" clearable>
          </el-input>
        </div>
        <div class="zhanghu">
          <div class="tie">密码</div>
          <el-input placeholder="请输入密码" v-model="entpassword" show-password @keyup.native.enter="user_login"></el-input>
        </div>
        <div class="zhanghu">
          <div class="tie">验证码</div>
          <el-input placeholder="请输入验证码" v-model="imageCode" @keyup.native.enter="user_login">
            <template slot="append">
              <div class="login-code" @click="refreshCode">
                <canvas id="canvas" height="40" width="116"></canvas>
              </div>
            </template>
          </el-input>
        </div>
        <div class="commit">
          <div @click="user_login" class="logincomit">登录</div>
        </div>
        <div class="logcont2">
          <div><el-checkbox v-model="isRemember">记住密码</el-checkbox></div>
          <!-- <div class="forgetPassword" @click="resetting()">忘记密码</div> -->
        </div>
        <div class="bottom">还没有帐号? <span class="clicktext" @click="goRegister()">点击注册</span></div>
      </div>
      <div v-if="isReset">
        <forgetPassword :original="original"></forgetPassword>
      </div>
      <div v-if="isRegister">
        <enteryRegister @change-grad="changeGrads" :isRegister="isRegister" :isReset="isReset"></enteryRegister>
      </div>
    </div>
  </div>
</template>
<script>
import { login } from "@api/user";
import forgetPassword from "./forgetPassword.vue"
import enteryRegister from "./enteryRegister.vue"
export default {
  components: {
    forgetPassword,
    enteryRegister
  },
  data() {
    return {
      username: "",
      password: "",    
      imageCode: '',
      verifyCode: '',
      original: 'login',
      isRemember: false,
      isLogin: true,
      isReset: false,
      isRegister: false,
      titleStatus: '用户登录',
      entname: "", //企业用户
      entpassword: "" //企业密码
    };
  },
  provide() {
    return {
      isRegister: this.isRegister,
      isReset: this.isReset,
      reserveFunc: this.reserveFunc, //传递方法     
      imageCode: this.imageCode,
      createCanvas: this.createCanvas,
      refreshCode: this.refreshCode,
    }
  },
  mounted() {
    this.verifyCode = this.createCanvas();
  },
  methods: {
    reserveFunc() {
      this.isReset = false
      this.isLogin = true
      this.titleStatus = '用户登录'
    },
    changeGrads() {
      // debugger
      this.titleStatus = '用户登录'
      this.isReset = false
      this.isRegister = false
      this.isLogin = true
    },  
    //地方用户登录
    user_login() {
      if (this.imageCode != this.verifyCode) {
        this.$message.error('验证码错误!');
        return;
      }
      let that = this;
      if (
        this.entname &&
        this.entname != "" &&
        this.entpassword &&
        this.entpassword != ""
      ) {
        let params = {
          username: this.entname,
          password: this.entpassword,
        };
        login(params).then((res) => {
          // console.log(res);
          if (
            res.data.type &&
            (res.data.type == 1 || res.data.type == 2 || res.data.type == 3)
          ) {
            that.$store.commit("set_user", res.data);
            that.$router.push({
              path: "/",
            });
          } else {
            that.$message.warning("普通用户暂不支持登录");
          }
        });
      } else {
        this.$message.warning("请填写用户名和密码");
      }
    },
    refreshCode() {
      this.$nextTick(function(){
        this.verifyCode = this.createCanvas();
      },500)
    },
    rn(min, max) {
      return parseInt((Math.random() * (max - min) + min).toString());
    },
    rc(min, max) {
      let r = this.rn(min, max);
      let g = this.rn(min, max);
      let b = this.rn(min, max);
      return `rgb(${r},${g},${b})`;
    },
    // 创建验证码
    createCanvas() {
      let ctx = document.getElementById('canvas').getContext('2d');
      ctx.fillStyle = this.rc(180, 230);
      ctx.fillRect(0, 0, 116, 40);
      let pool = '0123456789';
      let code = '';
      for (let i = 0; i < 4; i++) {
        let c = pool[this.rn(0, pool.length)];
        let fs = this.rn(24, 30);
        let deg = this.rn(-30, 30);
        ctx.font = fs + 'px sans-serif';
        ctx.textBaseline = 'top';
        ctx.fillStyle = this.rc(0, 200);
        ctx.save();
        ctx.translate(30 * i + 10, this.rn(12, 20));
        ctx.rotate((deg * Math.PI) / 180);
        ctx.fillText(c, 0, 0);
        ctx.restore();
        code += c;
      }
      for (let i = 0; i < 5; i++) {
        ctx.beginPath();
        ctx.moveTo(this.rn(0, 116), this.rn(0, 40));
        ctx.lineTo(this.rn(0, 116), this.rn(0, 40));
        ctx.strokeStyle = this.rc(0, 160);
        ctx.closePath();
        ctx.stroke();
      }
      for (let i = 0; i < 40; i++) {
        ctx.beginPath();
        ctx.arc(this.rn(0, 116), this.rn(0, 40), 1, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fillStyle = this.rc(0, 200);
        ctx.fill();
      }
      return code;
    },
    //忘记密码
    resetting() {
      this.titleStatus = '忘记密码'
      this.isReset = true
      this.isLogin = false
    },
    //注册
    goRegister() {
      this.isRegister = true;
      this.isLogin = false;
      this.titleStatus = '企业注册'
    }
  },
};
</script>
<style scoped>
/deep/.el-input--suffix .el-input__inner {
  /* width: 398px; */
  /* height: 60px; */
  /* font-size: 20px;
  color: #afafaf; */
}

/deep/.el-input-group__append {
  /* width: 574px; */
  padding: 0 !important;
}

/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
  color: #606266 !important;
}
</style>
<style scoped lang="scss">
.loginConiter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;
  background-size: 100% 100%;

  .forgetPassword {
    font-size: 14px;
    color: #0099fc;
    cursor: pointer;
  }

  .bottom {
    font-size: 14px;
    color: #333;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 100px;

    .clicktext {
      color: #3082e2;
      cursor: pointer;
    }
  }
}


.login-code {
  height: 38px;
}

.loginlabe {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // width: 900px;
  // margin: 0 auto;
  // padding-top: 170px;
  .lotitle {
    .first {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      margin: 40px 0;
      text-align: center;
    }

    .secong {
      font-size: 36px;
      font-weight: 800;
      color: #000000;
      // margin-bottom: 70px;
      text-align: center;
    }
  }

  .shuru {
    // padding-top: 90px;
    margin: 0 auto;
    width: 360px;
    height: 360px;
    border-radius: 20px;

    .zhanghu {
      // display: flex;
      // flex-direction: row;
      border-bottom: 1px solid #ffffff;
      margin-bottom: 24px;

      .tie {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        margin-bottom: 10px;
      }
    }
  }

  .logcont {
    margin-top: 27px;
    // width: 574px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .forget {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
    }
  }

  .logcont2 {
    margin-top: 27px;
    // width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .forget {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      text-align: center;
    }
  }

  .commit {
    margin-top: 57px;
    display: flex;
    justify-content: center;
    align-items: center;

    .logincomit {
      width: 378px;
      height: 40px;
      background: #164C90;
      color: #ffffff;
      font-size: 18px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
      border-radius: 5px;
    }

    .cont {
      width: 574px;
      margin-top: 24px;
      font-size: 20px;
      color: #979797;
      text-align: center;
      cursor: pointer;
    }
  }
}

.leftbg {
  width: 50%;
  height: 100%;
  background-color: #164C90;
}
</style>
