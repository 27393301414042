<template>
    <div>
        <div class="loginConiter">
            <div class="loginlabe">
                <div class="shuru">
                    <div class="zhanghu">
                        <div class="tie">账号</div>
                        <el-input placeholder="请输入账户" @keyup.native.enter="register_login" v-model="username" clearable>
                        </el-input>
                    </div>
                    <div class="zhanghu">
                        <div class="tie">重置密码</div>
                        <el-input placeholder="请输入密码" v-model="password" show-password
                            @keyup.native.enter="register_login"></el-input>
                    </div>
                    <div class="zhanghu">
                        <div class="tie">确认密码</div>
                        <el-input placeholder="请确认密码" v-model="passwordSure" show-password
                            @keyup.native.enter="register_login"></el-input>
                    </div>
                    <div class="commit">
                        <div @click="register_login" class="logincomit">登录</div>
                    </div>
                    <div class="bottom" @click="backLogin()">返回登录</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'forgetPassword',
    inject: ["isRegister", "isReset", "reserveFunc"],
    data() {
        return {
            username: '',
            password: '',
            passwordSure: '',
            agree: false,
            isRemember: false,
            isTrue: false,
            isforget: true
        }
    },
    props: {
        original: {
            type: String
        }
    },
    methods: {
        register_login() {
            let that = this
            // 判断密码是否一致
            if (that.passwordSure != that.password) {
                that.$message.warning("两次输入的密码不一致!");
                return false
            } else if (
                that.username &&
                that.username != "" &&
                that.password &&
                that.password != ""
            ) {
                let params = {
                    username: that.username,
                    password: that.password,
                    loginType: 1,
                    source: that.source
                };

            } else {
                that.$message.warning("请填写用户名和密码");
            }
        },
        //返回登录
        backLogin() {
            if (this.original === undefined) {
                // 先返回上一层父级
                this.$emit('change-value')
            } else if (this.original === "login") {
                //从最外面进来直接销毁
                this.reserveFunc()
            }
        }
    },
}
</script>
<style scoped lang="scss">
.loginlabe {
    .shuru {
        // padding-top: 90px;
        margin: 0 auto;
        width: 360px;
        height: 360px;
        border-radius: 20px;
        // padding: 70px;

        .zhanghu {
            border-bottom: 1px solid #ffffff;
            margin-bottom: 24px;

            .tie {
                font-size: 16px;
                font-weight: bold;
                color: #000000;
                margin-bottom: 10px;
            }
        }
    }

    .readtext {
        span {
            color: #409EFF;
        }
    }

    .logcont2 {
        margin-top: 27px;
        // width: 400px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .forget {
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            text-align: center;
        }
    }

    .forgetPassword {
        font-size: 14px;
        color: #0099fc;
        cursor: pointer;
    }

    .bottom {
        font-size: 14px;
        color: #0099fc;
        display: flex;
        flex-direction: row;
        justify-content: end;
        position: relative;
        top: 11px;
        cursor: pointer;

        .clicktext {
            color: #3082e2;
            cursor: pointer;
        }
    }

}

.commit {
    margin-top: 27px;
    display: flex;
    justify-content: center;
    align-items: center;

    .logincomit {
        width: 378px;
        height: 40px;
        background: #3082e2;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        border-radius: 5px;
    }

    .cont {
        width: 574px;
        margin-top: 24px;
        font-size: 20px;
        color: #979797;
        text-align: center;
        cursor: pointer;
    }
}

.logcont2 {
    margin-top: 27px;
    // width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .forget {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        text-align: center;
    }
}
</style>