/**
 * 检查密码强度
 * @param {*} pwd 
 * @returns 
 */
export function checkPwdStrong(pwd) {
    let score = 0
    // 必须同时包含数字和大小写字母
    if ((!pwd || pwd.length < 8) || (!/^(?=.*[0-9].*)(?=.*[a-z].*).{8,30}$/.test(pwd))) {
      return score
    }
    score++
    // 密码包含下划线
    if (/[_]/.test(pwd) || /^[A-Z]+$/.test(pwd)) {
      score++
    }
    return score
  }
  